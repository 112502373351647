import React, { useEffect, useState } from 'react'
import { StyleSheet, ScrollView, View, Linking } from 'react-native'
import { Portal, Modal, Text, Button, Card } from 'react-native-paper'
import { DateTime } from 'luxon'

import { Attendees } from '../Home/Attendees'
import { JoinButton } from '../Home/JoinButton'
import { ButtonWithConfirmModal } from '../../Components/ButtonWithConfirmModal'
import { getData } from '../../Components/useData'

export const EventDetail = ({
  auth,
  event,
  relatedEvents,
  isMgr,
  groupType,
  unSelect,
  updateEvents,
  onDelete,
}) => {
  const [attendees, setAttendees] = useState(null)

  useEffect(() => {
    getAttendees()
  }, [event])

  function cancel(e) {
    console.log(e)
    let data = { userkey: auth.sub, eventid: e.eventid }
    data['starttimestamp'] = e.occurrence_timestamp
    data['endtimestamp'] = e.occurrence_timestamp
    getData(
      groupType != 'university' ? 'fit_event_cancel' : 'fit_admin_event_cancel',
      data,
      () => {
        onDelete('Event cancelled.')
        updateEvents()
      }
    )
  }

  function cancelAll() {
    let data = { userkey: auth.sub, eventid: event.eventid }

    data['starttimestamp'] = relatedEvents.at(0).occurrence_timestamp
    data['endtimestamp'] = event.timestrings.keys().at(-1)
    getData(
      groupType != 'university' ? 'fit_event_cancel' : 'fit_admin_event_cancel',
      data,
      () => {
        onDelete(
          relatedEvents.length > 1
            ? 'Repeat event cancelled.'
            : 'Event cancelled.'
        )
        updateEvents()
      }
    )
  }

  function getDate(e) {
    if (!e) {
      return ''
    }
    return (
      e.datetime.weekdayShort +
      ' ' +
      e.datetime.monthShort +
      ' ' +
      e.datetime.day
    )
  }

  function getStartTime() {
    return convertToString(event.datetime)
  }

  function getEndTime() {
    let length =
      Number(event.endtime.substring(0, 2)) -
      Number(event.starttime.substring(0, 2))
    if (length < 0) {
      length =
        24 -
        Number(event.starttime.substring(0, 2)) +
        Number(event.endtime.substring(0, 2))
    }
    return convertToString(
      event.datetime
        .plus({ hours: length })
        .set({ minutes: event.endtime.substring(3) })
    )
  }

  function convertToString(datetime) {
    return datetime.toFormat('hh:mm a')
  }

  const getAttendees = () => {
    getData('fit_event_totals', { userkey: auth.sub }, (response) =>
      setAttendees(response[event.eventid])
    )
  }

  return (
    <>
      <Portal>
        <Modal visible={true} onDismiss={unSelect}>
          <Card key={event.eventid} style={styles.outer}>
            <Card.Title
              title={event.titletext}
              titleVariant='titleLarge'
              titleNumberOfLines={2}
              titleStyle={{
                marginTop: 10,
                marginBottom: 8,
              }}
              subtitle={event.wheretext}
              subtitleVariant='bodyMedium'
              rightStyle={{
                ...styles.title,
                paddingBottom: 42,
                paddingRight: 18,
              }}
              right={() => (
                <>
                  <Text
                    variant='titleMedium'
                    style={event.status == 'cancelled' && styles.strike}
                  >
                    {getDate()}
                  </Text>
                  <Text
                    variant='titleMedium'
                    style={event.status == 'cancelled' && styles.strike}
                  >
                    {getStartTime() + ' - ' + getEndTime()}
                  </Text>
                  {event.status == 'cancelled' && (
                    <Text variant='titleMedium' style={styles.cancelled}>
                      Cancelled
                    </Text>
                  )}
                </>
              )}
            />
            <Card.Content>
              <View style={{ ...styles.content }}>
                <>
                  <Text>{event.description}</Text>
                  {event.activity && (
                    <Text>{'Activity: ' + event.activity}</Text>
                  )}
                  <View style={styles.occurences}>
                    {relatedEvents.length > 1 ? (
                      <ScrollView>
                        <Text variant='labelLarge'>Occurs {event.type}</Text>
                        <View styles={styles.datelist}>
                          {relatedEvents.map((e, i) => {
                            return (
                              <View key={e.eventid + i} style={styles.li}>
                                <>
                                  <Text variant='titleSmall'>{getDate(e)}</Text>
                                  {isMgr && (
                                    <ButtonWithConfirmModal
                                      buttonIcon='close-circle'
                                      title={`Are you sure you want to cancel the occurence of this event on ${getDate(
                                        e
                                      )}?`}
                                      subtitle='This cannot be undone'
                                      confirmButtonText='Cancel event'
                                      cancelText='Nevermind'
                                      onConfirm={() => cancel(e)}
                                    />
                                  )}
                                </>
                              </View>
                            )
                          })}
                        </View>
                      </ScrollView>
                    ) : (
                      <Text variant='titleSmall' style={{ marginBottom: 13 }}>
                        {getDate(event)}
                      </Text>
                    )}
                    {isMgr && (
                      <ButtonWithConfirmModal
                        buttonText={
                          relatedEvents.length > 1 ? 'Cancel All' : 'Cancel'
                        }
                        buttonMode='outlined'
                        title={
                          relatedEvents.length > 1
                            ? `Are you sure you want to cancel all occurences of this event?`
                            : 'Are you sure you want to cancel this event?'
                        }
                        subtitle='This cannot be undone'
                        confirmButtonText={
                          relatedEvents.length > 1
                            ? 'Cancel All'
                            : 'Cancel Event'
                        }
                        cancelText='Nevermind'
                        onConfirm={cancelAll}
                      />
                    )}
                  </View>
                  {!isMgr && (
                    <Button
                      style={{ marginTop: 21, alignSelf: 'flex-start' }}
                      onPress={() =>
                        Linking.openURL(
                          'mailto:support@crimsonzip.com?subject=Report about event: ' +
                            event.eventid
                        )
                      }
                    >
                      Report Event
                    </Button>
                  )}
                </>
              </View>
              <View style={{ ...styles.footer }}>
                <Attendees auth={auth} event={event} attendees={attendees} />
                <JoinButton auth={auth} event={event} onJoined={updateEvents} />
              </View>
            </Card.Content>
          </Card>
        </Modal>
      </Portal>
    </>
  )
}

const styles = StyleSheet.create({
  outer: {
    marginHorizontal: 13,
    marginVertical: 21,
  },
  title: {
    padding: 21,
    height: '100%',
  },
  occurences: {
    maxHeight: 250,
  },
  content: {
    lineHeight: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  datelist: {
    display: 'block',
    maxHeight: 300,
    marginTop: 21,
  },
  li: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginVertical: 5,
  },
  time: {
    fontWeight: 600,
  },
  strike: {
    textDecorationLine: 'line-through',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 21,
    alignItems: 'flex-end',
  },
})
