import { useState, useEffect } from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import { Modal, Portal, Text, Button, TextInput } from 'react-native-paper'

import { theme } from '../../theme'
import { Load } from '../../Components/Load'
import { CustomCheckbox } from '../../Components/CustomCheckbox'

import { getData, postData } from '../../Components/useData'

export const JoinGroupsModal = ({
  auth,
  visible,
  hide,
  onJoin,
  currentMembership,
  type,
}) => {
  const [search, setSearch] = useState('')
  const [error, setError] = useState()

  const [groups, setGroups] = useState([])
  const [filteredGroups, setFilteredGroups] = useState([])
  const [chosenGroups, setChosenGroups] = useState([])
  const [load, setLoad] = useState(true)
  const [updating, setUpdating] = useState(false)

  const getPublicGroups = () => {
    getData(
      type == 'global' ? 'fit_group_directoryx' : 'fit_fgroup_directory',
      { userkey: auth?.sub, expanded: 'true' },
      (response) => setGroups(response),
      () => null,
      () => setLoad(false)
    )
  }

  const updateChosen = (group, isChecked) => {
    const i = chosenGroups.indexOf(group)
    const updatedGroups = [...chosenGroups]
    if (isChecked && i === -1) {
      updatedGroups.push(group)
    } else if (!isChecked && i !== -1) {
      updatedGroups.splice(i, 1)
    }
    setChosenGroups(updatedGroups)
  }

  const submit = () => {
    postMembershipUpdate(chosenGroups, true)
    // postMembershipUpdate(currentMembership.filter((m) => !chosenGroups.includes(m)), false)
  }

  function postMembershipUpdate(groupList, isJoining) {
    setUpdating(true)

    var data = {
      userkey: auth?.sub,
      grouplist: groupList,
    }

    postData(
      type == 'global' ? 'fit_group_addx' : 'fit_fgroup_add',
      data,
      () => onJoin(groupList.length),
      () => null,
      () => setUpdating(false)
    )
  }

  useEffect(() => {
    auth?.oAuthUser && getPublicGroups()
  }, [auth])

  useEffect(() => {
    filter()
  }, [groups, search])

  function filter() {
    if (search.length === 0) {
      setFilteredGroups(groups)
    } else {
      setFilteredGroups(
        groups.filter((g) =>
          g.search.toLowerCase().includes(search.toLowerCase())
        )
      )
    }
  }
  console.log(groups)

  return (
    <>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hide}
          contentContainerStyle={styles.modal}
          style={{ flexGrow: 1 }}
        >
          {load ? (
            <Load />
          ) : (
            <View style={{ height: '100%' }}>
              <Text variant='headlineMedium'>Select groups to join</Text>
              <TextInput
                style={{ marginVertical: 21 }}
                label='Search'
                value={search}
                onChangeText={(text) => setSearch(text)}
                mode='outlined'
                error={error}
              />

              <ScrollView style={{ flexGrow: 1 }}>
                {filteredGroups
                  .filter((g) => !currentMembership.includes(g.name))
                  .map((g, i) => (
                    <CustomCheckbox
                      label={g.name}
                      key={g.name}
                      isChecked={chosenGroups.includes(g.name)}
                      onSetChecked={(isChecked) =>
                        updateChosen(g.name, isChecked)
                      }
                    />
                  ))}
              </ScrollView>

              <View style={styles.buttons}>
                <Button style={styles.button} onPress={hide}>
                  Cancel
                </Button>
                <Button
                  style={styles.button}
                  mode='contained'
                  disabled={updating || chosenGroups.length === 0}
                  onPress={submit}
                >
                  {updating ? 'Joining' : 'Join'}
                </Button>
              </View>
            </View>
          )}
        </Modal>
      </Portal>
    </>
  )
}

const styles = StyleSheet.create({
  modal: {
    backgroundColor: theme.colors.background,
    padding: 21,
    margin: 21,
    borderRadius: 25,
    height: '90%',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 21,
  },
  button: {
    flexGrow: 0,
    marginLeft: 13,
  },
})
