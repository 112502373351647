import React, { useEffect, useState } from 'react'
import { withOAuth } from 'aws-amplify-react-native'
import {
  SafeAreaView,
  StyleSheet,
  ScrollView,
  View,
  FlatList,
} from 'react-native'
import {
  Appbar,
  Button,
  Text,
  List,
  Checkbox,
  RadioButton,
} from 'react-native-paper'

import { Tags } from './../Settings/Tags'
import { useForm, Controller } from 'react-hook-form'
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown'

import { CustomCheckbox } from '../../Components/CustomCheckbox'
import FormRadio from '../../Components/FormRadio'
import { theme } from '../../theme'
import { Load } from '../../Components/Load'
import { getData, postData } from '../../Components/useData'
import RegisterTags from './RegisterTags'

const Register = ({ auth, onRegister }) => {
  const [activityList, setActivityList] = useState([])
  const [tagList, setTagList] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [autoJoin, setAutoJoin] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const form = useForm({
    defaultValues: {
      sendemail: '',
      sendsms: '',
      groups: [],
      tags: [],
      confirm: {
        one: false,
        two: false,
        three: false,
      },
    },
  })

  useEffect(() => {
    if (auth?.sub) {
      getData(
        'fit_tag_get',
        {
          userkey: auth?.sub,
        },
        (data) => {
          setTagList(data)
        }
      )

      getData('fit_activity_directory', { userkey: auth?.sub }, (data) =>
        setActivityList(data)
      )
    }
  }, [auth])

  const onError = (e) => {
    console.log('error')
    console.log(e)
  }

  const onValidates = async (data) => {
    setSubmitting(true)

    data.tags = _.keys(data.tags).filter((d) => data.tags[d] == 'checked')

    data.userkey =
      auth?.oAuthUser?.signInUserSession?.accessToken?.payload?.sub || ''

    postData(
      'fit_account_registerx',
      data,
      () => null,
      () => {
        setSubmitting(false)
      },
      () => {
        onRegister()
      }
    )
  }

  return (
    <View style={styles.container}>
      <Appbar.Header
        elevated='true'
        theme={{ colors: { elevation: { level2: theme.colors.primary } } }}
      >
        <Appbar.Content title='Register' color={theme.colors.onPrimary} />
      </Appbar.Header>

      <RegisterTags tagList={tagList} auth={auth} form={form} />
      <View style={{ marginBottom: 40 }}>
        {form.formState.errors?.groups && (
          <Text style={{ ...styles.error, textAlign: 'center' }}>
            {form.formState.errors?.groups.message}
          </Text>
        )}
        <View
          style={{
            width: '100%',
            paddingHorizontal: 10,
          }}
        >
          <FlatList
            ListHeaderComponent={
              <View style={{ flexDirection: 'column', padding: 8 }}>
                <Text variant='titleMedium'>
                  By clicking the I CONFIRM button below, you certify as
                  follows:
                </Text>
              </View>
            }
            data={[
              {
                id: 'one',
                text: 'I accept and agree to be bound by the JoinU Terms of Service.',
              },
              {
                id: 'two',
                text: 'I consent to the collection and use of my information as described in the MyDataCan Privacy Statement and JoinU Privacy Addendum.',
              },
              {
                id: 'three',
                text: 'I understand that activities, events, and other convenings (“Activities”) posted through the JoinU Service (the “Service”) require varying levels of physical effort, and I represent and warrant that I am physically fit and have no medical condition that would prevent full participation in any Activities in which I choose to engage. I further understand that the Service does not vet or review Activities posted through the Service; does not review or assess the level of exertion or skill required to participate in any Activity; does not endorse or otherwise take a position on the content, purpose, or objectives of any Activity or the viewpoints expressed by its organizers or attendees, and does not endorse any training, advice, or instruction provided by any person in connection with an Activity.  I agree to assume full responsibility for any risks, injuries, or damages, known or unknown, that I may incur as a result of participating in any Activity.',
              },
            ]}
            keyExtractor={(item, i) => item.toString() + i.toString()}
            renderItem={({ item }) => (
              <CustomCheckbox
                label={item.text}
                key={'confirm' + item.id}
                styles={{ fontSize: 11, paddingHorizontal: 16 }}
                isChecked={form.watch('confirm.' + item.id)}
                onSetChecked={(isChecked) => {
                  if (isChecked) {
                    form.clearErrors('confirm')
                  }
                  form.setValue('confirm.' + item.id, isChecked ? true : false)
                }}
              />
            )}
            ListFooterComponent={<></>}
          />
        </View>
      </View>
      <Button
        style={styles.button}
        onPress={form.handleSubmit(onValidates, onError)}
        mode='contained'
        disabled={
          submitting ||
          !_.every(_.values(form.watch('confirm'))) ||
          _.isEmpty(_.keys(form.watch('tags')))
        }
      >
        {submitting ? 'Submitting' : 'Submit'}
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // position: 'absolute',
    width: '100%',
    height: '100%',
  },
  error: {
    color: theme.colors.error,
    display: 'flex',
    marginTop: 13,
    fontWeight: 500,
    marginHorizontal: 'auto',
  },
  button: {
    marginHorizontal: 'auto',
    marginVertical: 34,
    minWidth: 200,
    marginBottom: 100,
  },
})

export default withOAuth(Register)
