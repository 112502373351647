import React, { useEffect, useState } from 'react'
import { useTheme, IconButton } from 'react-native-paper'
import { View } from 'react-native'
import 'react-native-gesture-handler'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown'
import { Text } from 'react-native-paper'
import * as Linking from 'expo-linking'

import SettingsIcon from '@mui/icons-material/Settings'
import Login from './Pages/Login/Login'
import Register from './Pages/Login/Register'

import { Load } from './Components/Load'
import { getData } from './Components/useData'
import { Home } from './Pages/Home/Home'
import { Settings } from './Pages/Settings/Settings'
import { Friends } from './Pages/Friends/Friends'
import { useLinkTo } from '@react-navigation/native'
import { set } from 'lodash'

const Tabs = createBottomTabNavigator()

export const Main = ({ auth }) => {
  const theme = useTheme()
  const [loading, setLoading] = useState(true)
  const [register, setRegister] = useState(false)
  const [setup, setSetup] = useState(false)
  const [user, setUser] = useState(auth)

  const linkTo = useLinkTo()

  useEffect(() => {
    if (auth?.sub) {
      updateUser()
    }
  }, [auth, setup])

  useEffect(() => {
    setup &&
      getData(
        'fit_account_setup',
        {
          userkey: auth?.sub,
          emailaddress: auth?.oAuthUser.signInUserSession.idToken.payload.email,
          externalid:
            auth?.oAuthUser.signInUserSession.idToken.payload.identities[0]
              .userId,
        },
        () => setSetup(false),
        (error) => console.log(error)
      )
  }, [setup])

  const updateUser = () => {
    fetch(
      `${process.env.SERVER_URL}/fit_account_info?userkey=${auth?.sub}&appkey=${process.env.DATACAN_APP_KEY}`
    )
      .then((response) => {
        setRegister(response.status >= 400)
        setSetup(response.status == 400)
        return response.json()
      })
      .then((response) => {
        setUser({
          oAuthUser: auth.oAuthUser,
          sub: auth.oAuthUser.signInUserSession.accessToken.payload.sub,
          info: response,
        })
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }

  const removeUser = () => {
    console.log('logged out')
    setUser(null)
  }

  function fillScreen() {
    // if (l) {
    // 	return (<Load />)
    // } else
    if (user == null || user?.oAuthUser == null) {
      return <Login auth={auth} />
    } else if (register) {
      return <Register auth={user} onRegister={updateUser} />
    } else {
      return (
        <>
          <Tabs.Navigator
            initialRouteName='Friends'
            screenOptions={{
              headerShown: false,
              headerMode: 'screen',
              headerTintColor: theme.colors.onPrimary,
              headerStyle: { backgroundColor: theme.colors.primary },
              tabBarActiveTintColor: theme.colors.primary,
              tabBarInactiveTintColor: theme.colors.shadow,
              tabBarShowLabel: false,
              tabBarOptions: { showIcon: true },
              activeColor: theme.colors.primary,
              tabBarStyle: {
                elevation: 3,
                height: 80,
              },
            }}
          >
            <Tabs.Screen
              name='Home'
              options={{
                title: 'JoinU',
                tabBarIcon: ({ color }) => (
                  <IconButton icon={'menu'} iconColor={color} size={24} />
                ),
              }}
            >
              {() => <Home auth={user} />}
            </Tabs.Screen>
            <Tabs.Screen
              name='Friends'
              options={({ route }) => ({
                title: 'Friend Groups',
                tabBarIcon: ({ color }) => (
                  <View>
                    <IconButton icon={'account-group'} iconColor={color} />
                  </View>
                ),
              })}
            >
              {(props) => (
                <Friends {...props} auth={user} onChangedGroups={updateUser} />
              )}
            </Tabs.Screen>
            <Tabs.Screen
              name='Settings'
              options={{
                title: 'Settings',
                tabBarIcon: ({ color }) => (
                  <IconButton icon={'cog'} iconColor={color} size={24} />
                ),
              }}
            >
              {() => <Settings auth={user} onLogout={removeUser} />}
            </Tabs.Screen>
          </Tabs.Navigator>
        </>
      )
    }
  }

  // return <>{fillScreen()}</>
  return <Register auth={user} onRegister={updateUser} />
}
