import { useState, useEffect } from 'react'
import { Appbar, Button, Snackbar } from 'react-native-paper'
import { SafeAreaView, StyleSheet, ScrollView, View } from 'react-native'
import { useForm, Controller } from 'react-hook-form'

import { Notifications } from './Notifications'
import { Tags } from './Tags'
import { DeleteAccountButton } from './DeleteAccountButton'
import { Auth } from 'aws-amplify'
import { theme } from '../../theme'
import SettingTags from './SettingTags'

export const Settings = ({ auth, onLogout }) => {
  const [snack, setSnack] = useState('')
  const form = useForm({
    defaultValues: { settingTags: [] },
  })

  function logout() {
    Auth.signOut()
      .then((res) => {
        onLogout()
      })
      .catch((error) => console.log('error: ' + error))
  }

  return (
    <>
      <SafeAreaView style={styles.page}>
        <Appbar.Header
          theme={{
            colors: {
              surface: theme.colors.primary,
              onSurface: theme.colors.onPrimary,
            },
          }}
        >
          <Appbar.Content title='Settings' />
        </Appbar.Header>
        <ScrollView style={styles.scroll} alwaysBounceHorizontal={false}>
          {auth?.sub && (
            <>
              <SettingTags auth={auth} form={form} />
              <Notifications auth={auth} setSnack={setSnack} />

              <View style={styles.buttons}>
                <Button mode='contained' icon='logout' onPress={logout}>
                  Sign Out
                </Button>
                <DeleteAccountButton auth={auth} logout={logout} />
              </View>
            </>
          )}
        </ScrollView>
        <Snackbar visible={!!snack} onDismiss={() => setSnack('')}>
          {snack}
        </Snackbar>
      </SafeAreaView>
    </>
  )
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  buttons: {
    marginTop: 34,
    margin: 13,
    gap: 21,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
})
