import React, { useEffect, useState } from 'react'
import { Platform, Text, Linking as RNLinking } from 'react-native'
import { NavigationContainer, useRoute } from '@react-navigation/native'
import { PaperProvider, adaptNavigationTheme } from 'react-native-paper'
import * as Linking from 'expo-linking'
import * as WebBrowser from 'expo-web-browser'

import { Amplify, Auth } from 'aws-amplify'
import { withOAuth } from 'aws-amplify-react-native'
import awsmobile from './src/aws-exports'

import { theme } from './src/theme'
import { Main } from './src/Main'
import 'react-native-gesture-handler'
import { GestureHandlerRootView } from 'react-native-gesture-handler'

const isDevEnv =
  Platform.OS === 'web' && window.location.hostname.includes('dev')

const currentUrl =
  Platform.OS === 'web'
    ? isDevEnv
      ? process.env.SIGNIN_REDIRECT_QA
      : process.env.SIGNIN_REDIRECT_WEB
    : process.env.SIGNIN_REDIRECT_MOBILE

const updatedConfig = {
  ...awsmobile,
  oauth: {
    ...awsmobile.oauth,
    redirectSignIn:
      Platform.OS === 'web'
        ? isDevEnv
          ? process.env.SIGNIN_REDIRECT_QA
          : process.env.SIGNIN_REDIRECT_WEB
        : process.env.SIGNIN_REDIRECT_MOBILE,
    redirectSignOut:
      Platform.OS === 'web'
        ? isDevEnv
          ? process.env.SIGNIN_REDIRECT_QA
          : process.env.SIGNIN_REDIRECT_WEB
        : process.env.SIGNIN_REDIRECT_MOBILE,
  },
}

if (Platform.OS === 'ios') {
  const urlOpener = async (url, redirectUrl) => {
    const { type, url: newUrl } = await WebBrowser.openAuthSessionAsync(
      url,
      redirectUrl
    )

    if (type === 'success') {
      WebBrowser.dismissBrowser()
      return RNLinking.openURL(newUrl)
    }

    return null
  }
  updatedConfig.oauth.urlOpener = urlOpener
}

Amplify.configure(updatedConfig)

import { registerTranslation } from 'react-native-paper-dates'
registerTranslation('en-US', {
  save: 'Save',
  selectSingle: 'Select date',
  selectMultiple: 'Select dates',
  selectRange: 'Select period',
  notAccordingToDateFormat: (inputFormat) =>
    `Date format must be ${inputFormat}`,
  mustBeHigherThan: (date) => `Must be later then ${date}`,
  mustBeLowerThan: (date) => `Must be earlier then ${date}`,
  mustBeBetween: (startDate, endDate) =>
    `Must be between ${startDate} - ${endDate}`,
  dateIsDisabled: 'Day is not allowed',
  previous: 'Previous',
  next: 'Next',
  typeInDate: 'Type in date',
  pickDateFromCalendar: 'Pick date from calendar',
  close: 'Close',
})

const App = (auth) => {
  const [user, setUser] = useState(auth)
  const { navTheme } = adaptNavigationTheme({ reactNavigationLight: theme })
  const linking = {
    prefixes: [Linking.createURL()],
  }

  useEffect(() => {
    if (auth?.oAuthUser !== null) {
      setUser({
        ...auth,
        sub: auth.oAuthUser.signInUserSession.accessToken.payload.sub,
      })
    }
  }, [auth])

  return (
    <>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <PaperProvider theme={theme}>
          <NavigationContainer
            theme={navTheme}
            documentTitle={{
              formatter: (options, route) =>
                'JoinU' +
                (options?.title
                  ? ' - ' + options.title
                  : route
                  ? ' - ' + route.name
                  : ''),
            }}
            linking={linking}
            fallback={<Text>Loading...</Text>}
          >
            {/* <Stack.Screen name='Group' component={<div>hello</div>} /> */}
            <Main auth={user} />
          </NavigationContainer>
        </PaperProvider>
      </GestureHandlerRootView>
    </>
  )
}

export default withOAuth(App)
