import React, { useEffect, useState } from 'react'
import { StyleSheet, ScrollView, View, Linking } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Appbar, Text, Button, List, Snackbar } from 'react-native-paper'
import { DateTime } from 'luxon'

import { theme } from '../../../theme'
import { getData } from '../../../Components/useData'
import { EventLi } from './EventLi'
import { EventDetail } from '../EventDetail.js'
import { InviteModal } from './InviteModal'
import { DeleteGroup } from './DeleteGroup'

export const GroupDetail = ({ auth, navigation, onDelete }) => {
  const route = useRoute()

  const [memberCount, setMembers] = useState(null)
  const [description, setDescription] = useState(null)
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [snack, setSnack] = useState(null)

  const [selectedEvent, setEvent] = useState(null)

  useEffect(() => {
    if (auth?.oAuthUser) {
      getMemberInfo()
      getEvents()
    }
  }, [auth])

  useEffect(() => {
    if (route.params.snack) {
      setTimeout(() => setSnack(route.params.snack))
      getEvents()
    }
  }, [route])

  const getMemberInfo = () => {
    getData(
      'fit_fgroup_info',
      {
        userkey: auth?.sub,
        group: route.params.groupName,
      },
      (response) => {
        console.log(response)
        setMembers(response.total_members)
        setDescription(response.description)
      }
    )
  }

  const getEvents = () => {
    setLoading(true)
    getData(
      'fit_notifications_ndays',
      { userkey: auth.sub, ndays: 30 },
      (response) => {
        let es = response
          .map((d) => d._source)
          .filter((d) => d.group == route.params.groupName)
          .filter((e) => e.occurrence_timestring)
        es = es.map((e) => {
          const year = Number(e.occurrence_timestring.slice(0, 4))
          const month = Number(e.occurrence_timestring.slice(5, 7))
          const day = Number(e.occurrence_timestring.slice(8, 10))
          const hour = Number(e.occurrence_timestring.slice(11, 13))
          const min = Number(e.occurrence_timestring.slice(14, 16))
          return {
            ...e,
            datetime: DateTime.fromObject(
              { year: year, month: month, day: day, hour: hour, minute: min },
              { zone: e.timezone }
            ),
          }
        })
        setEvents(
          es.sort((a, b) => a.datetime.toSeconds() - b.datetime.toSeconds())
        )
      },
      () => {},
      () => setLoading(false)
    )
  }

  function componentDidMount() {
    getEvents()
  }

  function isMgr() {
    return (
      auth.info?.owner?.includes(route.params.groupName) ||
      auth.info?.delegates?.includes(route.params.groupName)
    )
  }

  function ownershipString() {
    if (auth.info?.owner?.includes(route.params.groupName)) {
      return ', you are an owner.'
    } else if (auth.info?.delegates?.includes(route.params.groupName)) {
      return ', you are a manager.'
    }
    return ''
  }

  function snackIt(msg) {
    console.log('snack: ' + msg)
    setSnack(msg)
  }

  return (
    <>
      <View
        style={{
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Appbar.Header
          mode='small'
          style={{ padding: 16, height: 112 }}
          theme={{
            colors: {
              surface: theme.colors.primary,
              onSurface: theme.colors.onPrimary,
            },
          }}
        >
          <Appbar.BackAction onPress={() => navigation.goBack()} />
          <View style={styles.header}>
            <Text variant='titleLarge' style={styles.title}>
              {route.params.groupName}
            </Text>
            {route.params.type && (
              <Text variant='titleMedium' style={styles.subtitle}>
                {route.params.type + ' Group'}
              </Text>
            )}
          </View>
        </Appbar.Header>
        <View
          style={{
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 100,
            overflowY: 'scroll',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <View style={styles.scroll}>
            <View>
              {isMgr() && (
                <InviteModal
                  auth={auth}
                  groupName={route.params.groupName}
                  setSnack={(msg) => snackIt(msg)}
                />
              )}
            </View>

            {description && (
              <View style={{ ...styles.buttons, marginVertical: 21 }}>
                <Text variant='bodyLarge'>{description}</Text>
              </View>
            )}

            <List.Section>
              <View style={{ ...styles.buttons, marginBottom: 13 }}>
                <Text>Events</Text>
                {isMgr() && (
                  <Button
                    mode='contained'
                    icon='plus-circle'
                    onPress={() =>
                      navigation.navigate('New Event', {
                        groupName: route.params.groupName,
                      })
                    }
                  >
                    Create event
                  </Button>
                )}
              </View>
              {events.length > 0 ? (
                events.map((e, i) => (
                  <EventLi
                    auth={auth}
                    onSelect={() => setEvent(e)}
                    event={e}
                    key={i}
                  />
                ))
              ) : (
                <>
                  {loading ? (
                    <Text>Loading...</Text>
                  ) : (
                    <Text>This group has no upcoming events</Text>
                  )}
                </>
              )}
            </List.Section>

            {auth.info?.owner?.includes(route.params.groupName) &&
              route.params.type != 'university' && (
                <View style={{ ...styles.buttons, marginVertical: 34 }}>
                  <View></View>
                  <DeleteGroup
                    auth={auth}
                    name={route.params.groupName}
                    onDelete={onDelete}
                    setSnack={setSnack}
                  />
                </View>
              )}

            {!isMgr() && route.params.type != 'university' && (
              <View
                style={{
                  ...styles.buttons,
                  marginVertical: 34,
                  paddingBottom: 40,
                }}
              >
                <View></View>
                <Button
                  onPress={() =>
                    Linking.openURL(
                      'mailto:support@crimsonzip.com?subject=Report about group: ' +
                        route.params.groupName
                    )
                  }
                >
                  Report Group
                </Button>
              </View>
            )}
          </View>
        </View>
      </View>
      <>
        {selectedEvent && (
          <EventDetail
            auth={auth}
            event={selectedEvent}
            relatedEvents={events.filter(
              (e) => e && e.eventid == selectedEvent.eventid
            )}
            isMgr={isMgr()}
            groupType={route.params.type}
            unSelect={() => setEvent(null)}
            onDelete={(msg) => {
              setEvent(null)
              setSnack(msg)
            }}
            updateEvents={getEvents}
          />
        )}
      </>
      <Snackbar visible={!!snack || false} onDismiss={() => setSnack(null)}>
        {snack}
      </Snackbar>
    </>
  )
}

const styles = StyleSheet.create({
  scroll: {
    paddingHorizontal: 16,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    padding: 16,
    justifyContent: 'flex-start',
  },
  title: {
    color: 'white',
  },
  subtitle: {
    color: 'white',
    textTransform: 'capitalize',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    marginTop: 13,
  },
})
